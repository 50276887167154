import { gsap } from "../../node_modules/gsap/index.js";

function gallerySlider() {
    const galleries = Array.from(document.querySelectorAll(".gallery"))
    const duration = 0.25;

    galleries.forEach(gallery => {
        const images = gallery.querySelectorAll("li")
        const tl = gsap.timeline()

        gallery.addEventListener("click", () => {
            const nextImage = Array.from(images).find(image => image.classList.contains("translate-y-full"))
            if (!nextImage) {
                images.forEach((image, index, all) => {
                    if (index === 0) { return }
                    if (index === all.length - 1) {
                        tl.to(image, {
                            opacity: 0,
                            duration,
                            onComplete: () => {
                                image.classList.add("translate-y-full")
                                image.classList.remove("translate-y-0")
                            }
                        })
                    } else {
                        tl.set(image, {
                            opacity: 0,
                        })
                        image.classList.add("translate-y-full")
                        image.classList.remove("translate-y-0")
                    }
                })
            } else {
                tl.to(nextImage, {
                    opacity: 1,
                    duration
                })
                nextImage.classList.remove("translate-y-full")
                nextImage.classList.add("translate-y-0")
            }


        })
    })
}

export default gallerySlider