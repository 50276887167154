function navigationSlidinOnPageScroll() {
  let previousScrollPosition = window.scrollY;
  const navigation = document.querySelector("#navigation-top-bar");
  const anchor = document.querySelector("#navigation-anchor");
  // const menuButton = document.querySelector(".navigation__menu-button");
  const menuOverlay = document.querySelector("#navigation__top-bar-overlay");



  window.addEventListener("scroll", () => {
    const start = 100;
    const currentScrollPosition = window.scrollY;
    const isScrollingUp = currentScrollPosition > start &&
      currentScrollPosition > previousScrollPosition;

    if (menuOverlay.classList.contains("visible")) {
      return
    }

    if (anchor) {
      const anchorPosition = anchor.getBoundingClientRect();
      const anchorIsSticky = anchorPosition.top < 87.8;

      if (isScrollingUp) {
        anchor.classList.remove("translate-y-[110%]");
      } else {
        if (anchorIsSticky) {
          anchor.classList.add("translate-y-[110%]");
        }
      }
    }

    if (isScrollingUp) {
      navigation.classList.add("translate-y-[-100%]");
    } else {
      navigation.classList.remove("translate-y-[-100%]");
    }

    // if (
    //   currentScrollPosition > start &&
    //   currentScrollPosition > previousScrollPosition &&
    //   !navigation.classList.contains("navigation--open")
    // ) {
    //   navigation.classList.add("navigation--slide-up");
    // } else {
    //   navigation.classList.remove("navigation--slide-up");
    // }

    previousScrollPosition = currentScrollPosition;
  });

  // menuButton.addEventListener("click", () => {
  //   if (
  //     navigation.classList.contains("navigation--open") &&
  //     navigation.classList.contains("navigation--slide-up")
  //   ) {
  //     navigation.classList.remove("navigation--slide-up");
  //   }
  // });
}

export default navigationSlidinOnPageScroll;
