import { gsap } from "gsap";

const duration = 0.2;

const lightMove = () => {
    const radial_1 = document.querySelector("#radial-1");
    const radialDimensions_1 = dimensions(radial_1)
    const INIT_LEFT_POSITION_1 = -radialDimensions_1.paddingX;
    const INIT_TOP_POSITION_1 = radialDimensions_1.paddingY;
    const SPEED_1 = window.innerWidth < 700 ? 0.5 : 1;

    moveCanvasInVP({
        radial: radial_1,
        dimensions: radialDimensions_1,
        INIT_LEFT_POSITION: INIT_LEFT_POSITION_1,
        INIT_TOP_POSITION: INIT_TOP_POSITION_1,
        SPEED: SPEED_1,
        OPPOSITE_DIRECTION: true,
    });

    const radial_2 = document.querySelector("#radial-2");
    const radialDimensions_2 = dimensions(radial_2)
    const INIT_LEFT_POSITION_2 = window.innerWidth - radialDimensions_2.radialDimensions.width;
    const INIT_TOP_POSITION_2 = 0;
    const SPEED_2 = window.innerWidth < 700 ? 0.75 : 1.5;


    moveCanvasInVP({
        radial: radial_2,
        dimensions: radialDimensions_2,
        INIT_LEFT_POSITION: INIT_LEFT_POSITION_2,
        INIT_TOP_POSITION: INIT_TOP_POSITION_2,
        SPEED: SPEED_2,
        OPPOSITE_DIRECTION: false,
    });
};

export default lightMove;

function dimensions(element) {
    const radialDimensions = element.getBoundingClientRect()
    const paddingX = radialDimensions.width / 2
    const paddingY = radialDimensions.height / 2
    return { radialDimensions, paddingX, paddingY }
}

function moveCanvasInVP({ radial, dimensions, INIT_LEFT_POSITION, INIT_TOP_POSITION, SPEED, OPPOSITE_DIRECTION }) {
    let currentAngle = getInitialRandomAngle();
    const { radialDimensions, paddingX, paddingY } = dimensions


    // Set the initial position of the box

    let currentLeftPosition = INIT_LEFT_POSITION;
    let currentTopPosition = INIT_TOP_POSITION;
    radial.style.left = currentLeftPosition + "px";
    radial.style.top = currentTopPosition + "px";

    // Function to move the canvas by approximately 1 pixel in the set angle
    function moveBox() {
        // hit RIGHT bounding box
        if (currentLeftPosition + radialDimensions.width > window.innerWidth + paddingX) {
            currentLeftPosition = window.innerWidth - paddingX - 1;
            if (getIsGoingDown(currentAngle)) {
                currentAngle -= Math.PI;
            } else {
                currentAngle += Math.PI;
            }
        }

        // hit LEFT bounding box
        if (currentLeftPosition < -paddingX) {
            currentLeftPosition = -paddingX + 1;
            if (getIsGoingDown(currentAngle)) {
                currentAngle -= Math.PI;
            } else {
                currentAngle += Math.PI;
            }
        }

        // hit BOTTOM bounding box
        if (currentTopPosition + radialDimensions.height > window.innerHeight + paddingY) {
            currentTopPosition = window.innerHeight - paddingY - 1;
            if (getIsGoingRight(currentAngle)) {
                currentAngle += Math.PI;
                currentAngle = 0 - currentAngle - Math.PI;
            } else {
                currentAngle -= Math.PI;
                currentAngle = 0 - currentAngle + Math.PI;
            }
        }

        if (currentTopPosition < -paddingY) {
            currentTopPosition = -paddingY + 1;
            if (getIsGoingRight(currentAngle)) {
                currentAngle += Math.PI;
                currentAngle = 0 - currentAngle - Math.PI;
            } else {
                currentAngle -= Math.PI;
                currentAngle = 0 - currentAngle + Math.PI;
            }
        }
        if (OPPOSITE_DIRECTION) {
            currentTopPosition -= Math.sin(currentAngle) * SPEED;
            currentLeftPosition -= Math.cos(currentAngle) * SPEED;
        } else {
            currentTopPosition += Math.sin(currentAngle) * SPEED;
            currentLeftPosition += Math.cos(currentAngle) * SPEED;
        }

        radial.style.left = currentLeftPosition + "px";
        radial.style.top = currentTopPosition + "px";
        requestAnimationFrame(moveBox);
    }

    requestAnimationFrame(moveBox);

    // Set the initial angle based on current mouse position
    document.addEventListener("mousemove", function (event) {
        const canvasDimensions = radial.getBoundingClientRect();
        const canvasCenterX = canvasDimensions.left + canvasDimensions.width / 2;
        const canvasCenterY = canvasDimensions.top + canvasDimensions.height / 2;
        const mouseX = event.clientX - canvasCenterX;
        const mouseY = event.clientY - canvasCenterY;
        currentAngle = Math.atan2(mouseY, mouseX);
    });
}

// Function to generate a random angle in radians,
// assuring the element will move downwards within a 45 degrees angle
function getInitialRandomAngle() {
    return Math.random() * (Math.PI / 2) + Math.PI / 4;
}

function getIsGoingDown(angle) {
    return angle > 0;
}

function getIsGoingRight(angle) {
    return 0 - Math.PI / 2 < angle && angle < Math.PI / 2;
}