import { gsap } from "../../node_modules/gsap/index.js";

function openerWords() {
    const words = Array.from(document.querySelectorAll(".opener-word"))

    words.forEach(word => {
        const tl = gsap.timeline()
        const duration = (Math.random() * 5) + 3
        const delay = (Math.random() * 1)
        const filter = "blur(10px)"

        const letter = word.querySelectorAll(".opener-letter")
        const tll = gsap.timeline()
        tll.set(letter, {
            fontVariationSettings: '"iktr" 100, "blln" 0, "curv" 0',

        })
        tll.to(letter, {
            fontVariationSettings: '"iktr" 100, "blln" 100, "curv" 100',
            duration: 0.75,
            stagger: 0.2,
            yoyo: true,
            repeat: 1,
            ease: "power1.out",
            repeatDelay: delay,
        })
        tl.to(word, {
            opacity: 0.1,
            filter,
            duration,
            delay,
        })
        word.addEventListener("mouseenter", () => {
            tl.clear()
            tl.to(word, {
                opacity: 1,
                filter: "blur(0px)",
                delay: 0,
                duration: 0.2,
            })
            tl.to(word, {
                opacity: 0.1,
                filter,
                delay: 0,
                duration,
            })
            tll.clear()
            tll.to(letter, {
                fontVariationSettings: '"iktr" 100, "blln" 100, "curv" 100',
                duration: 0.75,
                stagger: 0.2,
                ease: "power1.out",
            }).to(letter, {
                fontVariationSettings: '"iktr" 100, "blln" 0, "curv" 0',
                duration: 0.75,
                stagger: 0.2,
                ease: "power1.out",
            })
        })

        let previousScrollPosition = window.scrollY;
        let isActivated = true

        window.addEventListener("scroll", () => {
            const mark = window.innerHeight / 4
            const currentScrollPosition = window.scrollY;

            if (!isActivated && previousScrollPosition > currentScrollPosition && previousScrollPosition < mark) {
                isActivated = true
                tl.clear()
                tl.to(word, {
                    opacity: 1,
                    filter: "blur(0px)",
                    delay: 0,
                    duration: 0.2,
                })
                tl.to(word, {
                    opacity: 0.1,
                    filter,
                    delay: 0,
                    duration,
                })
                tll.clear()
                tll.to(letter, {
                    fontVariationSettings: '"iktr" 100, "blln" 100, "curv" 100',
                    duration: 0.75,
                    stagger: 0.2,
                    ease: "power1.out",
                }).to(letter, {
                    fontVariationSettings: '"iktr" 100, "blln" 0, "curv" 0',
                    duration: 0.75,
                    stagger: 0.2,
                    ease: "power1.out",
                })
            }
            if (previousScrollPosition > mark && isActivated) {
                isActivated = false
            }
            previousScrollPosition = currentScrollPosition;

        });

    })

}

export default openerWords