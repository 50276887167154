function navigationOverlayToggle() {
  const menuButton = document.querySelector("#navigation__top-bar-open");
  const menuClose = document.querySelector("#navigation__top-bar-close");
  const menuOverlay = document.querySelector("#navigation__top-bar-overlay");

  if (!menuButton) {
    return;
  }

  menuButton.addEventListener("click", () => {
    menuOverlay.classList.remove("invisible");
    menuOverlay.classList.add("visible");
  });
  menuClose.addEventListener("click", () => {
    menuOverlay.classList.remove("visible");
    menuOverlay.classList.add("invisible");
  });
}

export default navigationOverlayToggle;
